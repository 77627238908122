<template>
  <div class="paid-main">
    <div class="content-box">
      <div class="heard-box bgd-image">
        <div class="order-status-box">
          <div class="status-icon bgd-image"></div>
          <div class="status-text bgd-image"></div>
        </div>
        <div class="order-info">
          <div class="text-flex">
            <div class="flex-left font26">保险产品：</div>
            <div class="flex-right font26">{{productName}}</div>
          </div>
          <div class="text-flex">
            <div class="flex-left font26">订单编号：</div>
            <div class="flex-right font26">{{orderCode}}</div>
          </div>
          <!-- <div class="text-flex">
						<div class="flex-left">投保人：</div>
						<div class="flex-right">{{proposer.name}}</div>
					</div>
					<div class="text-flex">
						<div class="flex-left">被保人：</div>
						<div class="flex-right hidden-text">{{insureds | insureds}}</div>
					</div> -->
          <div class="text-flex">
            <div class="font20">* 具体内容以电子保单为准</div>
          </div>
        </div>
      </div>
      <div class="advertising-box">
        <!-- 中石油渠道 -->
        <div v-if="zsyAd" class="card-box" @click="toZSY">
          <img src="../../assets/images/pay/zsy.png" />
        </div>
        <div class="slice-pic-box">
          <img src="../../assets/images/pay/slice/01.png" />
          <img src="../../assets/images/pay/slice/02.png" />
        </div>
        <!-- <div class="advertising-1" @click="toHome">
					<img src="../../assets/images/pay/pay1.png" />
				</div> -->
        <div class="card-box" v-if="companyCode == 9803">
          <div class="advertising-2">
            <img src="../../assets/images/pay/pay2.png" />
          </div>
          <div class="advertising-3">
            <div class="qrCode-box"><img src="../../assets/images/pay/QrCode.png" /></div>
            <div class="qrCode-text">
              <div class="font28 fontColMinor lineHei42">扫码下载平安好生活APP</div>
              <div class="font28 fontColDef lineHei42">0元购好物&领券赢好礼</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="option-box">
      <div class="option-btn font32 fontColF" @click="toOrder">查看订单</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Unpaid',
  data () {
    return {
      orderId: null,
      orderCode: null,
      proposer: {},
      insureds: [],
      companyCode: null,
      productName: '',
      zsyAd: false
    }
  },
  filters: {
    insureds (val) {
      if (!val.length) return ''
      if (val.length == 1) return val[0].name
      else {
        let ins = []
        val.forEach(it => {
          ins.push(it.name)
        })
        return ins.join(' / ')
      }
    }
  },
  created () {
    this.orderId = this.$route.query.orderid || this.$route.query.orderId || null
    this.getOrderInfo()
  },
  mounted () {
    upsdk.pluginReady(function () {
      upsdk.setTitleStyle({
        navBackgroundColor: '0xffFFFFFF',
        appletStyle: 'black', //可选，black-黑色主题，white-白色主题
        backBtnVisible: '1', // 可选，左侧返回按钮是否显示。'0'不显示，'1'显示，不传或空则默认显示
        appletTitleBarVisible: '1',// 可选，标题栏是否显示。'0'不显示，'1'显示，默认显示
      });
    });
  },
  methods: {
    getOrderInfo () {
      let params = { id: this.orderId }
      this.$API.queryUnPayOrder(params).then(res => {
        console.log('res:', res)
        let { insureds, proposer, companyCode, orderCode, productName, zsyAd } = res.data || {}
        this.insureds = insureds
        this.proposer = proposer
        this.companyCode = companyCode
        this.orderCode = orderCode
        this.productName = productName || '北京普惠健康保'
        this.zsyAd = zsyAd || false
      }).catch(err => {
        console.log('err:', err)
      })
    },
    toOrder () {
      this.$router.replace({ path: '/order' })
    },
    toHome () {
      this.$router.replace({ path: '/' })
    },
    toZSY () {
      window.location.href = 'https://cq001.oiluck.cn/bjact025/auth/home'
    }
  }
}
</script>

<style lang="less" scoped>
.paid-main {
  width: 100%;
  height: 100vh;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  .content-box {
    width: 100%;
    height: calc(100% - 144px);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .heard-box {
    width: 100%;
    min-height: 500px;
    position: relative;
    background-image: url(../../assets/images/pay/pay_bj.png);
    padding: 68px 48px;
    .order-status-box {
      display: flex;
      align-items: center;
      .status-icon {
        width: 56px;
        height: 56px;
        margin-right: 24px;
        background-image: url(../../assets/images/pay/successful_icon.png);
      }
      .status-text {
        width: 188px;
        height: 46px;
        background-image: url(../../assets/images/pay/successful_text.png);
      }
    }
    .order-info {
      padding: 28px 0 0 16px;
      .text-flex {
        display: flex;
        align-items: center;
        color: #7d8ba2;
        margin-bottom: 10px;
        &:last-child {
          margin-top: 30px;
        }
        .flex-left {
          min-width: 140px;
        }
      }
    }
  }
  .slice-pic-box {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .advertising-box {
    width: 100%;
    flex: 1;
    margin-top: -150px;
    padding: 24px 32px;
    position: relative;
    z-index: 9;
    .advertising-1 {
      width: 100%;
      height: 292px;
    }
    .card-box {
      background: linear-gradient(360deg, #fff5ea 0%, #ffded3 100%);
      border-radius: 16px;
      margin-top: 24px;
      padding: 12px 20px 20px;
      .advertising-2 {
        width: 100%;
        height: 112px;
      }
      .advertising-3 {
        width: 100%;
        padding: 20px;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        border-radius: 8px;
        margin-top: 16px;
        .qrCode-box {
          width: 138px;
          height: 138px;
          margin-right: 24px;
        }
      }
    }
  }
  .option-box {
    width: 100%;
    padding: 24px 32px;
    background-color: #ffffff;
    display: flex;
    .option-btn {
      width: 100%;
      height: 96px;
      background-color: #fd3147;
      border-radius: 98px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.bgd-image {
  background-size: 100% 100%;
}
.lineHei42 {
  line-height: 42px;
}
.hidden-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
